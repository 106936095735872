export const PATHS = {
  login: "/",
  recoverPassword: "/recover-password",
  eBanking: "/menu",
  accounts: "/menu/accounts",
  accountDetails: "/menu/accounts/details",
  movements: "/menu/accounts/details/movements",
  transfers: "/menu/accounts/details/transfers",
  affiliations: "/menu/accounts/details/affiliations",
  customerRegistration: "/menu/customer-registration",
  legalEntity: "/menu/customer-registration/legal-entity",
  naturalPerson: "/menu/customer-registration/natural-person",
};

const {REACT_APP_ENV,REACT_APP_NAME,REACT_APP_API_BASE_KUBITQ, REACT_APP_API_BASE_URL, REACT_APP_API_BASE_URL_V2} = process.env

export const ENV = REACT_APP_ENV || 'development'

export const APP_NAME = REACT_APP_NAME || 'masefe'

const API_KUBITQ = `${REACT_APP_API_BASE_KUBITQ}${REACT_APP_API_BASE_URL}`;
const  API_KUBITQ_V2 = `${REACT_APP_API_BASE_KUBITQ}${REACT_APP_API_BASE_URL_V2}`;
export const URL_API = {
  AUTH: {
    LOGIN: `${API_KUBITQ}/auth/${APP_NAME}/${ENV}/sign-in`,
    LOGOUT: `${API_KUBITQ}/auth/sign-out`,
    ENROLL: `${API_KUBITQ}/ebanking/auth/2fa/enroll`,
    VALIDATE: `${API_KUBITQ}/ebanking/auth/2fa/validate`,
    ROLES: `${API_KUBITQ}/ebanking/auth/roles`,
    PROFILES: `${API_KUBITQ}/ebanking/auth/profiles`,
    AUTHUSER: `${API_KUBITQ}/ebanking/auth/users/authuser`,
    RECOVER_PASSWORD: `${API_KUBITQ}/auth/${APP_NAME}/${ENV}/recover-password`,
  },
  ACCOUNTS: {
    BASE: `${API_KUBITQ}/ebanking/accounts`,
    CUSTOMERS: `${API_KUBITQ}/ebanking/accounts/customers`,
    MOVEMENTS_LATEST: `${API_KUBITQ}/ebanking/accounts/#/movements/latest`,
    MOVEMENTS_PAGED: `${API_KUBITQ}/ebanking/accounts/#/movements/paged`,
    MOVEMENTS: `${API_KUBITQ}/ebanking/accounts/movements`,
    EXPORT: `${API_KUBITQ}/ebanking/accounts/file`
  },
  SPEI_SEND: `${API_KUBITQ}/ebanking/spei`,
  SPEI_BANKS: `${API_KUBITQ}/ebanking/spei/banks`,
  SPEI_ACCOUNT: `${API_KUBITQ}/ebanking/spei/account-types`,
  SPEI_PAYMENT: `${API_KUBITQ}/ebanking/spei/payment-types`,
  TRANSFERS_SEND: `${API_KUBITQ}/ebanking/transfers`,
  PARTNERS: `${API_KUBITQ}/ebanking/partners`,
  CUSTOMERS: `${API_KUBITQ}/ebanking/customers`,
  MONTHLY_REPORT: `${API_KUBITQ}/ebanking/monthly-report`,
  MOVEMENTS_REPORT: `${API_KUBITQ}/ebanking/movements-report`,
  MOVEMENTS: `${API_KUBITQ}/ebanking/movements`,
  ALL_ACCOUNTS: `${API_KUBITQ}/ebanking/all-accounts`,
  AFFILIATIONS: {
    BASE: `${API_KUBITQ}/ebanking/affiliations`,
    CARDS: `${API_KUBITQ_V2}/ebanking/affiliations`,
  },
  TRANSFERS_PENDING: {
    BASE: `${API_KUBITQ}/ebanking/spei/orders/saved`,
    APPROVE: `${API_KUBITQ}/ebanking/spei/orders/saved/process`,
  },
  CARDS: `${API_KUBITQ}/ebanking/cards`,
  MASS_TRANSFERS_SEND: `${API_KUBITQ}/ebanking/spei/bulk`,
};

export const MENUS = {
  LOGIN: 1,
  CUSTOMER_REGISTRATION: 2,
  ACCOUNTS: 3,
  HOME: 4,
};
/**
Menus backup
{
            name: 'Inicio',
            icon: 'home',
        },
        {
            name: 'Banca',
            icon: 'briefcase',
            suboptions: [
                { name: 'Mis clientes', icon: 'personal-card' },
                { name: 'Mis cuentas', icon: 'profile-2users' submenu: MENUS.CUSTOMER_REGISTRATION},
            ],
        },
        {
            name: 'Centro de ayuda',
            icon: 'help',
        },
 */

export const itemsSidenav = {
  options: [
    {
      name: "Inicio",
      icon: "home",
      menu: MENUS.HOME,
    },
    {
      name: "Dashboard",
      icon: "briefcase",
      suboptions: [
        {
          name: "Mis cuentas",
          icon: "profile2users",
          submenu: MENUS.ACCOUNTS,
        },
      ],
    },
    {
      name: "Cerrar Sesión",
      icon: "logout",
      menu: MENUS.LOGIN,
    },
  ],
};

export const ACCOUNT_DETAILS_OPTIONS = [
  { name: "Movimientos", icon: "movements", page: PATHS.movements },
  { name: "Transferir", icon: "wallet", page: PATHS.transfers },
  { name: "Afiliaciones", icon: 'profile-check', page: PATHS.affiliations}
];

export const TRANSFER_TAB = {
  TRANSFER: "Transfer",
  PENDING: "Pending",
  TRACKING: "Traking",
  FAVS: "Favs",
  BULK_TRANSFER: "BulkTransfer",
};
export const TRANSFERS_OPTIONS = [
  { name: "Transferencia", icon: "NA", page: TRANSFER_TAB.TRANSFER },
  { name: "Transferencias Masivas", icon: "NA", page: TRANSFER_TAB.BULK_TRANSFER },
  { name: "Transferencias Pendientes", icon: "NA", page: TRANSFER_TAB.PENDING },
  {
    name: "Seguimiento Transferencias",
    icon: "NA",
    page: TRANSFER_TAB.TRACKING,
  },
  { name: "Favoritos", icon: "NA", page: TRANSFER_TAB.FAVS },
];

export const MOVEMENTS_TYPE = {
  1: "SPEI",
  2: "SPEI",
  3: "Traspaso",
  4: 'Compra', 
  5: 'Retiro', 
  6: 'Comisión',
  7: 'Comisión por operación'
};

export const MARKETPLACE_OPTIONS = [
  { label: "Pago de servicios", icon: "receipt" },
  { label: "Market place", icon: "shop" },
  { label: "Carga de tiempo aire", icon: "cards" },
  { label: "Tarjetas de regalo", icon: "gift" },
];

export const LEGAL_ENTITY_FILES = {
  1: "Acta Constitutiva",
  2: "Constancia de Situación Fiscal",
  3: "Registro Publico del Comercio",
  4: "Comprobante de Domicilio con antigüedad no mayor a 3 meses",
  5: "Teléfono de Oficina",
  6: "Fotografías de Fachada e Interior de las oficinas. En caso de que aplique",
  7: "Poder del Representante Legal",
  8: "Identificación Oficial Vigente del Representante Legal",
  9: "Comprobante de Domicilio del Representante Legal",
  10: "Constancia de Situación Fiscal del Representante Legal",
  11: "Identificación Oficial de los miembros del Consejo de Administración",
  12: "Comprobante de domicilio de los miembros del Consejo de Administración",
  13: "Teléfonos de contacto de Representante Legal y Miembros del Consejo de Administración",
  14: "Correo electrónico de Representante Legal y Miembros del Consejo de Administración",
};

export const NATURAL_PERSON_FILES = {
  1: "ID Oficial de INE",
  2: "Pasaporte",
  3: "Comprobante de domicilio",
  4: "Constancia de Situación Fiscal",
  5: "Otro (Especifique)",
};

export const ROLES = {
  ROOT: 1,
};

export const CUSTOMER_TYPE = {
  PARTNER: 1,
  CLIENT: 2,
};

export const PROFILE_TYPE = {
  GOD: 1,
  CLIENT: 2,
};

export const ACCOUNT_TYPE = [ '', 'Cuenta', 'Subcuenta']

export const TAXPAYER_TYPE = ['', 'Fisica', 'Moral']

export const SESSION_TOKEN_KEY = "kubitq-token";
export const SESSION_CUSTOMER_KEY = "kubitq-customer";
export const SESSION_PROFILE_KEY = "kubitq-profile";
export const SESSION_PVK_KEY = "kubit-pvk"
export const SESSION_K_KEY = "kubit-k"

export const APP_TEXTS = ({
  masefe: {
    copyrightName: 'mas.efe',
    copyrightYear: '2024',
    displayName: 'MASEFE',
    transferName: '+EFE',
    boucherFooter: 'mas.efe',
    title: '+efe'
  },
  livingrock: {
    copyrightName: 'LivingRock',
    copyrightYear: '2024',
    displayName: 'LivingRock',
    transferName: 'LivingRock',
    boucherFooter: 'LivingRock',
    title: 'LR'
  },
  eplata: {
    copyrightName: 'Plata',
    copyrightYear: '2024',
    displayName: 'Plata',
    transferName: 'Plata',
    boucherFooter: 'Plata',
    title: 'Plata'
  },
  bitfi: {
    copyrightName: 'Bitfin',
    copyrightYear: '2024',
    displayName: 'Bitfin',
    transferName: 'Bitfin',
    boucherFooter: 'Bitfin',
    title: 'Bitfin'
  },
  '88pay': {
    copyrightName: '88pay',
    copyrightYear: '2024',
    displayName: '88pay',
    transferName: '88pay',
    boucherFooter: '88pay',
    title: '88pay'
  },
  'bambam': {
    copyrightName: 'BamBam',
    copyrightYear: '2024',
    displayName: 'BamBam',
    transferName: 'BamBam',
    boucherFooter: 'BamBam',
    title: 'BamBam'
  },
  'vive': {
    copyrightName: 'VIVECREDIT',
    copyrightYear: '2024',
    displayName: 'VIVE',
    transferName: 'Vive Credit',
    boucherFooter: 'Vive Credit',
    title: 'Vive'
  },
  'gipago': {
    copyrightName: 'GIPAGO',
    copyrightYear: '2024',
    displayName: 'Gipago',
    transferName: 'Gipago',
    boucherFooter: 'Gipago',
    title: 'gipago'
  },
  'black_plus': {
    copyrightName: 'BLACK +',
    copyrightYear: '2024',
    displayName: 'Black +',
    transferName: 'Black +',
    boucherFooter: 'Black +',
    title: 'Black+'
  }
})[APP_NAME]


export const CUSTOM_SIDENAV_STYLE= ({
  masefe: {
    classes: ['bg-gradient-to-b', 'from-primary', 'to-neutral-5', 'text-white', 'stroke-white'],
    img: '/img/masefe-bg-sidebar.png',
    iconClasses: [],
    subMenuClasess: ['bg-transparent', '[&.selected]:stroke-white', '[&.selected]:fill-white','[&.selected]:border-b-2','[&.selected]:border-b-tertiary', ['pl-6']],
    menuClasses: ['bg-transparent'],
    subMenuContainerClasses: ['bg-primary', 'bg-opacity-20'],
    menuSmImg: '/img/masefe-boucher.png',
    menuSmImgClass: 'p-5'
  },
  livingrock: {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  eplata: {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  bitfi: {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  '88pay': {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-specialBG' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-specialBG'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  'bambam': {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  'vive': {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  'gipago': {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  },
  'black_plus': {classes:['bg-white', 'text-neutral-5'],
    iconClasses: ['stroke-neutral-5','[&.selected]:stroke-black'],
    subMenuClasess: ['bg-neutral-1' , 'pl-6'],
    menuClasses: [],
    subMenuContainerClasses: ['bg-neutral-1'],
    menuSmImg: '',
    menuSmImgClass: 'bg-white'
  }
})[APP_NAME]

export const MOVEMENT_TYPE =['', 'SPEI', 'SPEI', 'Traspaso', 'Compra', 'Retiro', 'Comisión', 'Comisión por operación']
export const MOVEMENT_STATUS = {
    applied: "Liquidada",
    stoped:"Detenido/Holdeado",
    created : "Creado",
    pending : "Pendiente de envio",
    in_transit: 'Enviada',
    sent : "En proceso",
    scattered : "Liquidada",
    canceled : "Cancelada",
    returned : "Devuelto"
}