import {
  addCustomer,
  arrowDown,
  arrowLeft,
  arrowRight,
  calendarEdit,
  calendar,
  chart,
  check,
  chevron,
  close,
  copy,
  cross,
  documentText,
  documentUpload,
  editProfile,
  edit,
  error,
  exportIcon,
  eye,
  filters,
  help,
  importIcon,
  link,
  livingrockAppIcon,
  logout2,
  cards,
  gift,
  receipt,
  shop,
  masefeAppIcon,
  menu,
  money,
  more,
  notification,
  personalCard,
  schedule,
  searchNormal,
  search,
  security,
  addCustomerActive,
  addCustomerInactive,
  briefcase,
  home,
  logout,
  profile2usersActive,
  profile2usersInactive,
  success,
  movementsactive,
  movementsinactive,
  walletactive,
  walletinactive,
  view,
  warning,
  kubitq,
  livingrock,
  logo,
  masefe,
  iconCircle,
  keyboardArrowRight,
  keyboardArrowUp,
  grid,
  list
} from './icons';

const ICONS = {
  addCustomer,
  arrowDown,
  arrowLeft,
  arrowRight,
  calendarEdit,
  calendar,
  chart,
  check,
  chevron,
  close,
  copy,
  cross,
  documentText,
  documentUpload,
  editProfile,
  edit,
  error,
  export: exportIcon,
  eye,
  filters,
  help,
  import: importIcon,
  link,
  livingrockAppIcon,
  logout2,
  cards,
  gift,
  receipt,
  shop,
  masefeAppIcon,
  menu,
  money,
  more,
  notification,
  personalCard,
  schedule,
  searchNormal,
  search,
  security,
  addCustomerActive,
  addCustomerInactive,
  briefcase,
  home,
  logout,
  profile2usersActive,
  profile2usersInactive,
  success,
  movementsactive,
  movementsinactive,
  walletactive,
  walletinactive,
  view,
  warning,
  kubitq,
  livingrock,
  logo,
  masefe,
  iconCircle,
  keyboardArrowRight,
  keyboardArrowUp,
  grid,
  list
};

const Icon = ({ iconName, ...props }: any) => {
  const Component = ICONS[iconName as keyof typeof ICONS];
  if (!Component) return <></>;
  return <Component {...props} />;
};

export default Icon;
