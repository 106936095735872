import React from 'react'

export const grid = ({fill, stroke, className}) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/menu">
        <g id="menu">
        <g id="Group">
        <path id="Vector" d="M11.6932 5.54001C12.599 5.54001 13.3332 4.80576 13.3332 3.90001C13.3332 2.99426 12.599 2.26001 11.6932 2.26001C10.7875 2.26001 10.0532 2.99426 10.0532 3.90001C10.0532 4.80576 10.7875 5.54001 11.6932 5.54001Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M4.30651 5.54001C5.21226 5.54001 5.9465 4.80576 5.9465 3.90001C5.9465 2.99426 5.21226 2.26001 4.30651 2.26001C3.40076 2.26001 2.6665 2.99426 2.6665 3.90001C2.6665 4.80576 3.40076 5.54001 4.30651 5.54001Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M11.6932 13.74C12.599 13.74 13.3332 13.0057 13.3332 12.1C13.3332 11.1942 12.599 10.46 11.6932 10.46C10.7875 10.46 10.0532 11.1942 10.0532 12.1C10.0532 13.0057 10.7875 13.74 11.6932 13.74Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M4.30651 13.74C5.21226 13.74 5.9465 13.0057 5.9465 12.1C5.9465 11.1942 5.21226 10.46 4.30651 10.46C3.40076 10.46 2.6665 11.1942 2.6665 12.1C2.6665 13.0057 3.40076 13.74 4.30651 13.74Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
        </g>
    </svg>
  )
}
