import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react';
import { CollapsTableProps } from './model';
import { Row } from './componentes';


export const CollaspsTable = ({
    colums,
    data,
    CollapsComponent,
    minWidth,
}: CollapsTableProps) => {
    const [tableData, setTableData] = useState<any[]>([]);

    useEffect(() => {
        if (data) {
          setTableData(data);
        }
    }, [data]);

     
    return (
        <div className='w-full overflow-auto'>
            <div className={`w-full overflow-auto min-w-[${minWidth ?? '690px'}]`}>
                <div className="table-container w-full flex flex-col bg-white">
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    {
                                        colums.map(col => (
                                            <TableCell className='!p-2 !font-ArialBold !text-[12.5px] !leading-normal !tracking-tight'>{col.name}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row: any) => (
                                    <Row key={row.id} row={row} colums={colums} CollapsComponent={CollapsComponent}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )   
}
