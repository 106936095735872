import { useState, useEffect } from 'react';
import {SearchInput} from '../search-input';
import { Tooltip } from 'react-tooltip';
import {ProfileMenu} from '../profile-menu';
import type { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { setCustomerInfo, setPartnerInfo } from '../../redux/slices/profileSlice';
import { CustomersServices, PartnersService } from '../../service';
import { useSpinner } from '../../hooks/useSpinner';
import './Header.scss';
import { APP_NAME } from '../../utils/constants';
import Icon from '../../assets/icon';
import { setUser } from '@sentry/react';
import { getName } from '../../utils/functions';
import { Modal } from '../modal';
import { CreateOTP } from '../../pages/modal-content/create-otp';

/**
 * Encabezado principal del portal
 * Contiene la información personal del Contratante/Colaborador, asi como las notificaciones.
 */
export const Header = () => {
    const [newOTPModal, setNewOTPModal] = useState<boolean>(false);
    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const { customer_id, customer_type, username } = useSelector((state: RootState) => state.user.profile);
    const { contact_name, contact_email } = useSelector((state: RootState) => state.user.information);
    const [loadingSpinner] = useSpinner();
    const dispatch = useDispatch();

    /**
     * Obtiene la informacion personal del cliente
     * Dependiendo del tipo de cliente, se valida si es colaborador o contratante
     * El tipo de cliente se obtiene del JWT de la respuesta del Login
     */
    const getCustomerInfo = async () => {
        // Cliente contratante
        if (customer_type === 1) {
            const respPartner = await PartnersService.getPartnerById(customer_id);
            if (respPartner) {
                setUser({
                    email: respPartner.contact_email,
                    username: getName(respPartner),
                    id: username
                })
                dispatch(setPartnerInfo(respPartner));
            } 
        } else {
            // Cliente colaborador
            const respCustomer = await CustomersServices.getCustomerById(customer_id);
            if (respCustomer) {
                setUser({
                    email: respCustomer.contact_email,
                    username: getName(respCustomer),
                    id: username
                })
                dispatch(setCustomerInfo(respCustomer));
            }
        } 
    };

    /**
     * Obtiene las iniciales del nombre para mostralas en el perfil
     */
    const getInitials = () => {
        if (contact_name) {
            const names = contact_name.split(' ');
            return `${(names[0] || '').charAt(0)}${(names[1] || '').charAt(0)}`
        }
        return '';
    }

    /**
     * Ejecuta api para obtener la informacion personal al inicio del componente
     */
    useEffect(() => {
        getCustomerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
//container justify-self-center
    return(
    <div className='flex w-full h-full justify-start bg-header text-headerContrast border-b'>
        <div className='header-component flex w-full h-full items-center pl-1'>
            <div className='flex items-center justify-start gap-2 flex-1 float-start pl-8'>
                <img src={`/img/logo/${APP_NAME}.svg`} alt='logo'  className='hidden lg:block'/>
            </div>
            {/* <div className='search-container hidden lg:flex items-center justify-center flex-3 '>
                <SearchInput
                    type='header'
                    placeholder={'Buscar en la plataforma'}
                    callback={(e: string) => console.log(e)}
                />
            </div> */}
            <div className='profile-container flex justify-end items-center w-[383px] box-border pr-4 float-end flex-1'>
                {/** DESCOMENTAR CUANDO SE TENGA LA FUNCIONALIDAD DE NOTIFICACIONES */}
                {/*<div className='notification'>0</div>*/}
                <div className='flex w-[25px] h-[48px] content-center justify-center flex-wrap'>    
                    <div className='w-[24px] h-[24px]'>
                        <Icon iconName='notification' className='stroke-headerContrast w-full h-full' alt='notification' />
                    </div>
                </div>
                <div className='cursor-pointer flex flex-row justify-evenly items-center' onClick={() => setOpenTooltip(!openTooltip)}
                    data-tooltip-id='my-tooltip'
                    data-testid="open-tooltip"
                >
                <div className='flex justify-center items-center h-[31px] ml-4 rounded-full text-1 w-12 sm:w-8 bg-userIconBg text-userIconColor'>{getInitials()}</div>
                <div className='text-[16px] leading-normal cursor-pointer ml-[9px]'>Hola, {contact_name.split(' ')[0]}</div>
                    <img
                        src={`/img/icons/chevron.svg`}
                        alt='chevron'
                        className={!openTooltip ? 'chevron rotate' : 'chevron'}
                    />
                    <Tooltip
                        id='my-tooltip'
                        className='tooltip-header'
                        openOnClick={true}
                        noArrow
                        place='bottom-end'
                        offset={28}
                        clickable
                        isOpen={openTooltip}
                    >
                        <ProfileMenu name={contact_name.split(' ')[0]} email={contact_email} createOTP={() => setNewOTPModal(true)}/>
                    </Tooltip>
                </div>
            </div>
        </div>
        <Modal open={newOTPModal} title="" padding="24px 0px" onClose={() => setNewOTPModal(false)}>
            <CreateOTP />
        </Modal>
    </div>
    
    );
};
