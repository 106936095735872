import React from 'react'
import { ICardAffiliation } from '../../interfaces'

interface ICreditCard {
    card: ICardAffiliation,
    view: 'grid' | 'list'
}

export const CreditCard = ({card, view}: ICreditCard) => {
    const isGrid = view === 'grid';

    const getClasses = () => {
        if(isGrid) return 'p-3 w-[288px] h-[198px]';
        return 'px-5 py-2 w-full h-[60px] transition-all duration-500 ease-in-out transform';
    }

    const styleCard = (reverse?: boolean) => {
        if(isGrid) return `flex flex-col justify-between ${reverse ? 'items-end' : 'items-start'}`;
        return `flex items-center ${reverse ? 'flex-row-reverse' : 'flex-row'}`;
    }

    const imgBank = (typeBank: string) => {
        const catBanks: { [key in string]?: any } = {
            'visa': <img src={`/img/icons/banks/visa.svg`} alt='visa' className='h-[18px]'/>,
            'master': <img src={`/img/icons/banks/master.svg`} alt='master' className='h-[18px]'/>,
        }
    
        return catBanks[typeBank.toLocaleLowerCase()];
    }

  return (
    <div className={`flex rounded-[12px] font-ArialRegular leading-normal border border-gray-200 shadow-lg bg-gradient-to-r from-header to-white ${getClasses()}`}
    >
        <div className={`w-1/2 gap-10 ${styleCard()}`}>
            <div>
                <div className='text-[11px]'>Tarjeta</div>
                <div className='text-[14px]'>{card.masked_pan}</div>
            </div>
            <div>
                <div className={`text-[11px] ${isGrid ? 'hidden' : 'block'}`}>Tipo</div>
                <div className='text-[14px] flex'>
                    <span className='first-letter:uppercase lowercase'>{card.type}</span>
                    <span className={`${isGrid ? 'hidden' : 'block'} first-letter:uppercase lowercase`}>&nbsp;{card.active_function}</span>
                </div>
            </div>
        </div>
        <div className={`gap-10 w-1/2 ${styleCard(true)}`}>
            {imgBank(card.brand)}
            <div className={`text-[14px] first-letter:uppercase lowercase ${isGrid ? 'block' : 'hidden'}`}>{card.active_function}</div>   
        </div>
    </div>
  )
}
