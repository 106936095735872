import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IAlert } from '../../interfaces/utilsInterface';

const initialState: IAlert = {
    show: false,
    text: '',
    severity: 'error',
    time: 0
};

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert: (state, action: PayloadAction<IAlert>) => {
            state.show = action.payload.show;
            state.text = action.payload.text? action.payload.text : 'Ha ocurrido un error';
            state.time = action.payload.time? action.payload.time : 1000 * 10;
            state.severity = action.payload.severity? action.payload.severity : 'error';
        },
    },
});

export const { setAlert } = alertSlice.actions;
export const alert = (state: RootState) => state.alert;
export default alertSlice.reducer;
