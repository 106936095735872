import React, { useState } from 'react'
import Icon from '../../../assets/icon'
import { CreditCard } from '../../../components';
import { ICardAffiliation } from '../../../interfaces';

type TypesView = 'grid' | 'list'

export const ListCard = ({ row }: any) => {
  const [view, setView] = useState<TypesView>('grid');

  return (
    <div className='flex gap-4'>
      <div className='flex items-start'>
        <button data-testid="btn-grid" className="p-2" onClick={() => setView('grid')}>
          <Icon iconName="grid" className={`p-1 ${view === 'grid' ? 'stroke-[#3E23E2]' : 'stroke-neutral-4'} hover:stroke-[#3E23E2]`}/>
        </button>
        <button data-testid="btn-list" className="p-2" onClick={() => setView('list')}>
          <Icon iconName="list" className={`p-1 ${view === 'list' ? 'stroke-[#3E23E2]' : 'stroke-neutral-4'} hover:stroke-[#3E23E2]`}/>
        </button>
      </div>
      <div className={`grid m-auto px-2 pb-3 transition-all duration-500 ease-in-out ${view === 'grid' ? 'grid-cols-3 min-w-max gap-6' : 'grid-cols-1 w-full gap-4'} max-h-[445px] overflow-auto`}>
          {
            row.cards.map((card: ICardAffiliation, index: number) => <CreditCard key={index} card={card} view={view} />)
          }
      </div>

    </div>
  )
}
