import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './slices/profileSlice';
import spinnerReducer from './slices/spinnerSlice';
import accountsReducer from './slices/accountsSlice';
import notificationReducer from './slices/notificationSlice';
import alertReducer from './slices/alertSlice';
import transferReducer from './slices/transferSlice';
export const store = configureStore({
  reducer: {
    user: profileReducer,
    spinner: spinnerReducer,
    accounts: accountsReducer,
    notification: notificationReducer,
    alert: alertReducer,
    transfer: transferReducer
  }
})

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
