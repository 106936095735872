import React from 'react'

export const list = ({fill, stroke, className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M2 4.66675H14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M2 8H14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M2 11.3333H14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}
