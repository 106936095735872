import { useState, Fragment } from "react";
import { CollapsTableColumn } from "../../model";
import Icon from "../../../../assets/icon";
import { Box, Collapse, TableCell, TableRow } from "@mui/material";

interface RowProps {
    row: CollapsTableColumn,
    colums: Array<{ [key: string]: any }>,
    CollapsComponent: React.FunctionComponent<{ row: any }>;
}

export const Row = ({ row, colums, CollapsComponent }: RowProps) => {
    const [open, setOpen] = useState(false);

    const KeyboardArrowIcon = () => (
        <button data-testid="btn-keyboard" className="action-table-btn p-2 hover:bg-neutral-2 rounded-full" onClick={() => setOpen(!open)}>
            <Icon iconName={ open ? 'keyboardArrowUp' : 'keyboardArrowRight'} className="p-1 stroke-neutral-4"/>
        </button>
    )
  
    return (
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            {
                colums.map(({index, CustomComponent, valueFormatter, isActions}: any) => {
                    let value = ''
                    try {
                        value = eval('row.'+index)
                    } catch (error) {
                        value = '-'
                    }

                    return(
                        <TableCell key={index} className="!p-2 !font-ArialRegular !leading-normal !text-[12.5px]">
                            <div className={`flex gap-2 ${isActions ? 'justify-center' : 'justify-start' }`}>
                                {CustomComponent && <CustomComponent {...row} value={value} />}
                                {isActions && KeyboardArrowIcon()}
                                {!CustomComponent && (valueFormatter ? valueFormatter(value, row) : value)}
                            </div>
                        </TableCell>
                    )
                })
                    
              }
        </TableRow>
        <TableRow className="bg-specialBG">
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ padding: '24px 0px' }}>
                {CollapsComponent && <CollapsComponent row={row}/>}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
}
