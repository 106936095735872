/**
 * Descarga un archivo local
 * @param fileUrl ruta del archivo
 * @param fileName nombre del archivo cuando se descargue
 * @returns funcion para descargar
 */
export const useDownloadFileLocal = () => {

    const downloadFile = (fileUrl: string, fileName: string) => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

  return { downloadFile };
};
